<template>
  <div>
    <h2>To do list</h2>
    <div style="display: flex; flex-direction: column; flex: 1">
      <it-tabs style="flex: 1">
        <it-tab title="Patience">
          <ul>
            <li>Advanced Options</li>
            <li>Cards in play (4 standard, 3 easy 5+ hard)</li>
            <li>Total decks x2, x3, x4, 1/2</li>
            <li>Custom decks / deck length (X suits, Y kings/aces, x/52 cards in deck)</li>
            <li>Retro mode / Full "A4" cards</li>
            <li>Add "AI" to workout score/simulate - calculate best possible score : including all correct and some wrong.</li>
            <li>Plot possible scores on bell curve</li>
          </ul>
        </it-tab>
        <it-tab title="Other">
          <ul>
            <li>Regular Solitaire</li>
            <li>Metronome with circle rings made of dashses. increasing gaps the further from the circle.</li>
          </ul>
        </it-tab>
        <it-tab title="Coming Soon" disabled>Coming Soon</it-tab>
      </it-tabs>
    </div>
  </div>
</template>
